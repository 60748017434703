import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/analytics-script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/lemon-affiliate-script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/lemon-script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/logsnag-script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/opt/build/repo/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/styles/global.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Outfit\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"font\"}");
